
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";

interface Setting {
  code: string;
  value: string;
}

export default defineComponent({
  name: "OtherSetting",
  setup() {
    // vue variables
    const { t } = useI18n();
    // page variables
    const allowCrossBet = ref(false);
    const settings: any = ref();

    /**
     * Set Settings
     *
     */
    const setSettings = () => {
      if (settings.value.CROSS_BETS)
        allowCrossBet.value = Boolean(settings.value.CROSS_BETS);
    };

    /**
     * Get Settings
     *
     */
    const getSettings = async () => {
      const settingsArr: Setting[] = await ApiService.get(
        "/game/settings/other"
      )
        .then((res) => res.data)
        .catch(() => []);

      if (settingsArr.length > 0) {
        settings.value = settingsArr.reduce(
          (obj, item) => ((obj[item.code] = item.value), obj),
          {}
        );
        setSettings();
      }
    };

    /**
     * Update Cross Bet
     *
     */
    const updateCrossBet = async () => {
      try {
        await ApiService.patch("/game/settings/cross-bets", {
          value: String(allowCrossBet.value),
        });
      } catch (e) {
        return false;
      }
    };

    /**
     * On Mounted
     *
     */
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("Menu.OtherSetting"), [t("Menu.GameManage")]);
      getSettings();
    });

    return {
      allowCrossBet,
      updateCrossBet,
      setSettings,
    };
  },
});
