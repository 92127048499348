import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "card col-12 col-xl-3 col-lg-3 col-md-4" }
const _hoisted_3 = { class: "card-body pt-5" }
const _hoisted_4 = { class: "fv-row mt-5" }
const _hoisted_5 = { class: "form-check form-switch form-check-custom form-check-solid" }
const _hoisted_6 = { class: "fs-6 fw-bold required" }
const _hoisted_7 = {
  class: "form-check-label",
  for: "flexSwitchChecked"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("OtherSetting.AllowCrossBet")), 1),
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input ml-2",
              type: "checkbox",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.allowCrossBet = $event)),
              onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateCrossBet && _ctx.updateCrossBet(...args)))
            }, null, 544), [
              [_vModelCheckbox, _ctx.allowCrossBet]
            ]),
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.allowCrossBet
                ? _ctx.$t("OtherSetting.Allowed")
                : _ctx.$t("OtherSetting.NotAllowed")), 1)
          ])
        ])
      ])
    ])
  ]))
}